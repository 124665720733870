<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader" style="min-width: 100px">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :default-value="filters.customer_goods_barcode"
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :default-value="filters.keywords"
                label="SKU | UID"
                placeholder="SKU | UID"
                name="keywords"
                sort-name="keywords"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                label="Code | IMEI"
                placeholder="Code | IMEI"
                name="barcode"
                sort-name="barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectEmployee
                :label="$t('labels.employee_create')"
                :placeholder="$t('labels.employee_create')"
                name="id_employee"
                sort-name="employee_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="statusOption"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.tracking_goods_receipt_history')"
                :placeholder="$t('labels.tracking_goods_receipt_history')"
                name="tracking_goods_receipt_history"
                sort-name="tracking_goods_receipt_history"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.tracking_goods_issue_detail')"
                :placeholder="$t('labels.tracking_goods_issue_detail')"
                name="tracking_goods_issue_detail"
                sort-name="tracking_goods_issue_detail"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.tracking_goods_issue_detail_return')"
                :placeholder="$t('labels.tracking_goods_issue_detail_return')"
                name="tracking_goods_issue_detail_return"
                sort-name="tracking_goods_issue_detail_return"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter
                :label="$t('labels.export')"
                :placeholder="$t('labels.export')"
                name="pickup_at"
                sort-name="pickup_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter
                :label="$t('labels.return_at')"
                :placeholder="$t('labels.return_at')"
                name="return_at"
                sort-name="return_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <DateRangeFilter
                :label="$t('labels.expired_date')"
                :placeholder="$t('labels.expired_date')"
                name="expired_date"
                sort-name="expired_date"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.lot_tracking')"
                :placeholder="$t('labels.lot_tracking')"
                name="lot_tracking"
                sort-name="lot_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`${key}_${item.id}`"
            class="text-center"
          >
            <!-- <td>{{ item.warehouse_code }}</td> -->
            <td>{{ item.customer_goods_barcode }}</td>
            <td>
              <span v-if="!item.barcode">
                <span
                  class="text-decoration-underline primary--text cursor-pointer"
                  @click="showHistoryDialog(item)"
                >
                  {{ `${item.sku}@${item.uid}` }}
                </span>
                <v-icon
                  class="cursor-pointer"
                  small
                  color="primary"
                  @click="downloadStamp(item)"
                  >mdi-download</v-icon
                >
              </span>
              <span v-else>{{ item.sku }}</span>
            </td>
            <td>
              <span
                class="text-decoration-underline primary--text cursor-pointer"
                @click="showHistoryDialog(item)"
              >
                {{ item.barcode }}
              </span>
            </td>
            <td>{{ item.employee_name }}</td>
            <td>
              <span
                v-if="
                  checkPermission(['goods_uid_roll']) &&
                  [1, 2, 3].includes(item.status)
                "
                class="error--text text-decoration-underline cursor-pointer"
                @click="showRollUid(item)"
              >
                {{ item.available ? "Yes" : "No" }}
              </span>
              <span v-else>{{ item.available ? "Yes" : "No" }}</span>
            </td>
            <td>{{ statusTxt[item.status] }}</td>
            <td>{{ item.tracking_goods_receipt_history }}</td>
            <td>
              <OrderTracking
                v-if="item.tracking_goods_issue_detail"
                :tracking-id="item.tracking_goods_issue_detail"
              />
            </td>
            <td>
              <OrderTracking
                v-if="item.tracking_goods_issue_detail_return"
                :tracking-id="item.tracking_goods_issue_detail_return"
              />
            </td>
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ item.pickup_at ? formatDateTime(item.pickup_at) : "" }}</td>
            <td>{{ item.return_at ? formatDateTime(item.return_at) : "" }}</td>
            <td>
              {{
                item.expired_date
                  ? formatDateTime(item.expired_date, "DD/MM/YYYY")
                  : ""
              }}
            </td>
            <td>{{ item.lot_tracking }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div
          class="d-flex table-footer-count mt-3"
          v-if="
            filters.sku || filters.customer_goods_barcode || filters.keywords
          "
        >
          <div class="mr-2">
            {{ $t("labels.sum") }}: <b>{{ formatNumber(totalItem) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.available") }}:
            <b>{{ formatNumber(totalAvailable) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col sm="3">
        <div class="d-flex align-center">
          <v-btn
            color="success"
            @click="exportExcel"
            v-if="
              checkPermission(['goods_uid_manager']) &&
              (filters.sku ||
                filters.customer_goods_barcode ||
                filters.keywords)
            "
            style="height: 40px"
            class="mr-2"
            ><v-icon>mdi-download</v-icon> {{ $t("labels.excel") }}</v-btn
          >
          <v-text-field
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="rollUidDialog" persistent max-width="350px">
      <RollUid
        v-if="rollUidDialog"
        :item="updatingItem"
        @cancel="hideRollUid"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="historyDialog" persistent max-width="1024px">
      <UidHistory
        v-if="historyDialog"
        :item="updatingItem"
        @close="hideHistoryDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import uid from "@/components/goods/mixins/uid";

export default {
  name: "Uid",
  components: {
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
    RollUid: () => import("@/components/goods/RollUid"),
    UidHistory: () => import("@/components/goods/UidHistory"),
  },
  mixins: [uid],
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
