import { YES_NO_OPTIONS, GOODS_UID_STATUS_OPTIONS } from "@/libs/const";
import {
    debounce,
    downloadExcelFile,
    downloadPdfFile,
    formatDateTime,
    formatNumber,
    getUrlParameter,
    isMobile
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
    props: {
        sku: {
            type: [Number, String],
            default: () => { }
        }
    },
    data: () => ({
        page: 1,
        totalPage: 1,
        totalItem: 0,
        totalAvailable: 0,
        items: [],
        filters: {},
        yesNoOptions: [...YES_NO_OPTIONS],
        statusOption: [...GOODS_UID_STATUS_OPTIONS],
        statusTxt: {},
        isLoading: false,
        rollUidDialog: false,
        updatingItem: {},
        sum: {},
        qrScanDialog: false,
        qrScanType: null,
        historyDialog: false,
    }),
    watch: {
        page() {
            this.getList()
        },
    },
    created() {
        if (this.sku) {
            this.filters = this.filters = { ...this.filters, sku: this.sku }
        }
        const keywords = this.getUrlParameter('keywords')
        const customer_goods_barcode = this.getUrlParameter('barcode')
        if (keywords) {
            this.filters = { ...this.filters, keywords }
        }
        if (customer_goods_barcode) {
            this.filters = { ...this.filters, customer_goods_barcode }
        }
        const statusTxt = {
            1: this.$t('labels.uid_status_1'),
            2: this.$t('labels.uid_status_2'),
            3: this.$t('labels.uid_status_3'),
            4: this.$t('labels.uid_status_4'),
            5: this.$t('labels.uid_status_5'),
            6: this.$t('labels.uid_status_6'),
            7: this.$t('labels.uid_status_7'),
            0: this.$t('labels.uid_status_0'),
        }
        this.statusTxt = { ...statusTxt }
    },
    methods: {
        formatDateTime,
        downloadPdfFile,
        downloadExcelFile,
        formatNumber,
        getUrlParameter,
        isMobile,
        showUidDialog() {
            this.uidDialog = true
        },
        hideUidDialog() {
            this.uidDialog = false
            this.uidSku = null
        },
        showQRCodeScan(type) {
            this.qrScanDialog = true
            this.qrScanType = type
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
            this.qrScanType = null
        },
        onQRScanned(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
            this.getList()
        },
        showRollUid(item) {
            this.updatingItem = { ...item }
            this.rollUidDialog = true
        },
        hideRollUid() {
            this.updatingItem = {}
            this.rollUidDialog = false
        },
        showHistoryDialog(item) {
            this.updatingItem = { ...item }
            this.historyDialog = true
        },
        hideHistoryDialog() {
            this.updatingItem = {}
            this.historyDialog = false
        },
        downloadStamp(item) {
            this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-one-stamp`, {
                id: item.id,
            }, `50x20_${item.sku}_${item.id}.pdf`)
        },
        getList: debounce(function () {
            if (this.isMobile() && !this.filters.keywords) {
                return false
            }
            if (this.filters.keywords && !this.filters.keywords.includes('@') && this.isMobile()) {
                this.filters = { ...this.filters, keywords: null }
                return false
            }
            httpClient.post('/goods/v1/uid-list', { ...this.filters, page: this.page }).then(({ data }) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = { ...data.sum }
                this.totalItem = data.total
                this.totalAvailable = data.total_available

                if (this.isMobile()) {
                    this.filters = { ...this.filters, keywords: null }
                }
            })
        }, 500),
        onFilterChange(filter) {
            if (filter.name === 'sku') {
                let valueSpl = filter.value && filter.value.split('@') || ['']
                this.filters = { ...this.filters, [filter.name]: valueSpl[0] }
            } else {
                this.filters = { ...this.filters, [filter.name]: filter.value }
            }
        },
        onSortChange(sort) {
            this.filters = { ...this.filters, ...sort }
        },
        async exportExcel() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.waiting_file_download'))
                return false
            }
            this.isLoading = true
            const filename = 'ds-uid.xlsx'

            try {
                await this.downloadExcelFile('/goods/v1/uid-export', { ...this.filters }, filename)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
            }
        },
    },
}
